import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Accordion } from '../../../../components'

const WhatWeDo = ({
  data,
  t,
  i18n,
}) => {
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    if (data) {
      setSelected(data.list[0])
    }
  }, [data])

  const onHandleSelected = (i) => {
    setSelected(data.list[i])
  }

  return (
    <div className='row whatWeDo__container'>
      <div className='col-12'>
        <h2>{_.get(data, 'title')}</h2>
      </div>
      <div className='col-12'>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <Accordion data={data.list} i18n={i18n} t={t} onHandleSelected={onHandleSelected}/>
          </div>
          <div className='col-12 col-md-7 '>
            <div
              className='whatWeDo__image'>
              <img src={_.get(selected, 'image.url')} alt={_.get(selected, 'image.alt')} />
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default WhatWeDo
