import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React from 'react'

import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import IconArrow from '../../../../assets/icons/seasongroup_icons_arrow-right.svg'
import {
  Carousel,
  Tagline,
} from '../../../../components'
import { useTranslation } from 'react-i18next'

const CardOthersIndustries = ({
  title,
  content,
  slug,
  t,
}) => {
	const { i18n } = useTranslation();
  const breakpoints = useBreakpoint()
  return (
    <div className="cardOthersIndustries__container">
      <div className="cardOthersIndustries__content">
        <h3>{title}</h3>
        <HTMLEllipsis
          unsafeHTML={content}
          maxLine={breakpoints.xs ? 12 : 6}
          ellipsis='...'
          basedOn='words'
        />
        <div className="cardOthersIndustries__arrow">
          <Link to={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}industries/${slug}`}>
            {t('industries.other.more')}
          </Link>
          <IconArrow fill="#018AFF" width="20" />
        </div>
      </div>
    </div>

  )
}


const OthersIndustries = ({
  data,
  t,
}) => (
  <React.Fragment>
    <div className="carousel__tagline">
      <h2>{t('industries.other.title')} </h2>
    </div>
    <div className="otherIndustries__container">
      <Carousel
        data={data.list}
        sToShow={2}
        sToScroll={2}
        arrows={true}
        dots={false}

      >
        {data.list.map((industry, i) => <div key={i}>
          <CardOthersIndustries t={t} {...industry}
          />
        </div>)}
      </Carousel>
    </div>
  </React.Fragment>
)

export default OthersIndustries
