import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import {
  Seo,
  BannerCTA,
  Spinner,
  StickyMenu,
  CaseStudies,
} from "../../components"
import {
  HeaderServices,
  OtherIndutries,
  WhatWeDo,
  ExperiencesIndustries,
} from "./components"
import "./industries-styles.scss"

const IndustriesView = ({ data, loading }) => {
  const { t } = useTranslation()

  const menu = [
    // {
    //   name: t('industries.certification.title'),
    //   id: 'certifications',
    // },
    {
      name: t("industries.experiences.title"),
      id: "experiences",
    },
    {
      name: t("industries.what.title"),
      id: "what-we-do",
    },
    {
      name: t("industries.case.title"),
      id: "case-studies",
    },
  ]

  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <React.Fragment>
      <div className="industries__container">
        <Seo title={_.get(data, "title")} />
        <StickyMenu menu={menu} slug={`industries/${data?.slug}`} />
        <div className="industries__box">
          {_.map(_.get(data, "acf.rows"), (layouts, idx) => {
            let layout
            switch (true) {
              case layouts.acf_fc_layout === "hero_two_images_copy":
                layout = (
                  <div key={idx} className="industries__content">
                    <HeaderServices
                      title={_.get(data, "title")}
                      data={layouts}
                      t={t}
                    />
                  </div>
                )
                break
              // ask for the client to hide Ramon julia 4/11/2022
              // case layouts.acf_fc_layout === 'certification' && layouts.hide_section === true:
              //   layout = <div key={idx} className='industries__content'>
              //     <CertificationsIndustries data={layouts} t={t} />
              //   </div>
              //   break

              case layouts.acf_fc_layout === "cards_image":
                layout = (
                  <div
                    key={idx}
                    className="industries__content"
                    id="experiences"
                  >
                    <ExperiencesIndustries data={layouts} t={t} />
                  </div>
                )
                break

              case layouts.acf_fc_layout === "accordion":
                layout = (
                  <div
                    key={idx}
                    className="industries__content"
                    id="what-we-do"
                  >
                    <WhatWeDo data={layouts} t={t} />
                  </div>
                )
                break
              // ask for the client to hide Ramon julia 4/11/2022
              case layouts.acf_fc_layout === "case_studies_list":
                layout = (
                  <div
                    key={idx}
                    className="industries__content caseStudies"
                    id="case-studies"
                  >
                    <CaseStudies data={layouts} t={t} />
                  </div>
                )
                break
              case layouts.acf_fc_layout === "industries_cards_list":
                layout = (
                  <div key={idx} className="industries__content others">
                    <OtherIndutries data={layouts} t={t} />
                  </div>
                )
                break
              default:
                break
            }
            return layout
          })}
        </div>
      </div>
      <BannerCTA />
    </React.Fragment>
  )
}

export default IndustriesView
